@import '../../../../styles/colors.module.scss';

.HeaderActionContainer{
  display: flex;
  align-items: center;
  .regBtn{
    margin-left: 15px;
  }
}

@media (max-width: 760px) {
  .regBtn {
    display: none;
  }
}