@import '../../../styles/colors.module.scss';

.popupContainer{
  max-width: 100%;
  width: 600px;
  height: 450px;
  display: flex;
  flex-direction: column;

  @media (max-width: 576px) {
    height: 550px;
  }

  .tabBar{
    display: flex;
    border-bottom: 1px solid $mainBorder;

    .tabItem{
      flex: 1 1;
      padding: 14px 0px;
      text-align: center;
      font-weight: 600;
      cursor: pointer;
      &.active{
        color: $darkLightTextColor;
        border-bottom: 3px solid $darkLightTextColor;
      }
      &:hover{
        color: $darkLightTextColor;
      }
    }
  }
  .mainContent{
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  .footer{
    border-top: 1px solid $mainBorder;
    padding: 15px 15px 5px 15px;
    display: flex;
    .footerItem{
      padding: 0px 10px;
      margin-bottom: 10px;
      flex: 1;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
}