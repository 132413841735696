@import '../../../styles/colors.module.scss';
.listContainer{
  .doctorItemContainer{
    margin-bottom: 20px;
    border-radius: 7px;
    border: 1px solid $mainBorder;
    .imageWraper{
      width: 100%;
      height: 100%;
      overflow: hidden;
      img{
        object-position: top;
        object-fit: cover;
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        &:hover{
          transform: scale(1.1) !important;
        }
      }
    }

    .itemBody{
      padding: 20px;
      border-right: 1px solid $mainBorder;
      h5{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      a{
        color: $mainTitleColor;
        &:hover{
          color: $darkLightTextColor;
        }
      }
      .expertise{
        font-weight: 600;
        margin-bottom: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
      .school{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .buttonWraper{
        margin-top: 20px;
        display: flex;
        align-items: center;
        button:last-child{
          margin-left: 10px;
        }
      }
    }
    .itemRight{
      padding: 20px;
    }

    .itemRight{
      height: 100%;
      padding: 20px;
      .rightWraper{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .rightMain{
          .rightItem{
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            svg{
              color: $mainTitleColor;
              margin-right: 10px;
            }
          }
        }
        .review{
          svg{
            color:#efb000;
          }
        }
      }
    }
  }
}

@media(max-width: 768px){
  .imageWraper{
    height: 250px !important;
  }
}