.inIcon {
  position: absolute;
  top: 0px;
  margin-top: 17px;
  color: $mainTextColor;
  z-index: 3;

  &.rightIcon {
    right: 10px;
  }

  &.leftIcon {
    left: 10px;
  }
}

.input-group {
  flex-wrap: nowrap !important;
}

.form-control,
.form-select {
  font-size: 15px;
  letter-spacing: 0;
  padding: 14px 20px;
  border-radius: 0;
  transition: all .3s ea;
  color: $mainTextColor;
  display: block;
  flex: 1;
  width: 100%;
  outline: none;
  border: 1px solid #e5e5e5;

  &.prevIcon {
    padding-left: 35px !important;
  }

  &.nextIcon {
    padding-right: 35px !important;
  }

  &.noBorder {
    border: none !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darkLightBackground  !important;
  }

  &.smInput {
    padding: 5px 15px;
  }
}

// -------- custom search select ------------------
.select-search {
  width: 100%;
  height: 100%;
  .select-search__value {
    height: 100%;
    input {
      height: 100%;
      border: 1px solid $mainBorder;
      box-shadow: none;
      padding: 0px 10px 0px 35px;
      color: $mainTextColor;
    }
    &::after {
      display: none;
    }
  }
}
.select-search__select{
  position: absolute !important;
  top: 55px !important;
  z-index: 10 !important;
  .select-search__options {
    list-style: none;
    padding: 0;
  }
  .select-search__option.is-selected {
    background: #56c0d3;
    border-radius: 0px !important;
    &:hover{
      background: #56c0d3;
    }
  }
}



// ------ checkbox, radio -------------
input[type=checkbox],
input[type=radio] {
  opacity: 0;
  position: absolute;
}

.form-label {
  height: 100%;
  text-align: right;
  line-height: 1;
  font-size: 14px;
  padding-top: 10px;
  display: block;
}



label:not(.form-label) {
  cursor: pointer;
  position: relative;
  padding-left: 25px;
}

input[type=checkbox]+label:before,
input[type=radio]+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  border: 1px solid #d4dadb;
  border-radius: 3px;
  display: inline-block;
  flex-shrink: 0;
  height: 16px;
  outline: 0;
  transition: background-color .2s ease-out;
  width: 16px;
}

input[type=radio]+label:before {
  border-radius: 50%;
}

input[type=checkbox]+label:after,
input[type=radio]+label:after {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 10px;
  height: 5px;
  content: " ";
  -webkit-transform: translateY(-50%) rotate(-45deg);
  transform: translateY(-50%) rotate(-45deg);
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  opacity: 0;
  transition: .3s;
}

input[type=radio]+label:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 0;
  background-color: #00acb1;
}

input[type=checkbox]:checked+label.switch-toggle:before,
input[type=checkbox]:checked+label:before {
  background-color: #00acb1;
  border-color: #00acb1;
}

input[type=checkbox]:checked+label:after,
input[type=radio]:checked+label:after {
  opacity: 1;
}

@media (max-width: 768px) {
  label {
    justify-content: start !important;
    text-align: left !important;

    &.text-end {
      text-align: left !important;
      padding-left: 0px !important;
    }
  }
}