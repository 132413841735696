@import '../../../styles/colors.module.scss';

.bottomSpace{
  background-color: $lightGreenBackground;
  padding-bottom: 155px;
}
.ourteamContainer {
  position: relative;
  background-image: url('../../../../public/images/img/our-team-bg.jpg');
  background-position: center;
  background-size: cover;
  padding: 130px 0px 80px;
  z-index: 1;

  &::before{
    position: absolute;
    content: "";
    background-color: rgba(0,89,99,.6);
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .titleContainer{
    color: $darkgroundTextColor;
    text-align: center;
    margin-bottom: 50px;
    .smallTitle{
      font-size: 18px;
      font-weight: 600;
    }
    h3{
      color: $darkgroundTextColor;
    }
  }
}

.teamSliderWraper{
  position: relative;
  margin-bottom: -200px;
}

.slideContainer{
  border-radius: 7px;
  background-color: $lightBackground;
  color: $mainTextColor;
  height: 100%;
  border: 1px solid $mainBorder;
  .imageWraper{
    width: 100%;
    height: 280px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      transition: all 0.3s;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      &:hover{
        transform: scale(1.1) !important;
      }
    }
  }
  .slideContent{
    padding: 20px;
    a{
      color: $mainTitleColor;
      line-height: 1;
      &:hover{
        color: $darkLightTextColor;
      }
    }
    .doctorName, .doctorExpertise{
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .doctorName{
      a{
        font-size: 18px;
        font-weight: 700;
      }
    }
    .doctorExpertise{
      a{
        font-size: 16px;
        font-weight: 600;
      }
    }
    .userCountryContainer{
      font-weight: 600;
      display: flex;
      align-items: center;
      svg{
        color: $darkLightTextColor;
      }
    }
  }
}