@import '../../../styles/colors.module.scss';

.homeContainer{
  padding: 20px;
  h1{
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 10px 0px 30px;
    @media (max-width: 768px) {
      font-size: 21px !important;
    }
  }

  .appointmentRow{
    margin-bottom: 30px;
    h2{
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      @media (max-width: 768px) {
        font-size: 17px !important;
      }
    }
    .rowDes{
      font-size: 14px;
      
    }
  }
}