@import '../../../styles/colors.module.scss';

.whyWeWraper{
  padding: 100px 0;
  background-image: url('../../../../public/images/img/why_we_background.png');
  background-color: $darkBackground;
  color: $darkgroundTextColor;
  h3{
    line-height: 1.2;
    margin-bottom: 20px;
    color: $darkgroundTextColor;
    text-align: left;
  }
  p{
    opacity: 0.7;
    line-height: 1.7;
    margin-bottom: 30px;
  }
  .whyweItem{
    display: flex;
    h5{ 
      color: $darkgroundTextColor;
    }
  }

  .whyImgContainer{
    .imageItem{
      position: relative;
      display: inline-block;
      width: 400px;
      height: 300px;
      border-radius: 10px;
      object-fit: cover;
      &.secondImg{
        top: -70px;
        left: calc(100% - 400px);
      }
    }
  }
}

@media (max-width: 972px){
  .whyweRow{
    flex-direction: column-reverse;
  }
}

@media (max-width: 576px){
  .whyImgContainer{
    margin-bottom: 30px;
  }
  .imageItem.secondImg{
    display: none !important;
  }
  .imageItem{
    width: 100% !important;
  }
}