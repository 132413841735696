.loadingContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #a7ada799;
  top: 0px; 
  left: 0px;
  img{
    width: 50px;
    height: 50px;
  }
}