@import '../../../styles/colors.module.scss';

.counterContainer {
  z-index: 2;
  position: relative;
  padding: 40px;
  margin: 20px 0 -200px;
  background-color: $darkLightBackground;
  color: $darkgroundTextColor;
  font-weight: 700;

  .counting {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;

    svg {
      stroke-width: 1
    }
  }

  .countDes {
    font-size: 16px;
    text-align: center;
  }
}