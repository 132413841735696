@import '../../../styles/colors.module.scss';

.blogsContainer{
  .blogItemWraper{
    margin-bottom: 30px;
  }
  .pagenationWraper{
    margin-bottom: 50px;
    text-align: center;
  }
}