@import '../../../../styles/colors.module.scss';

.contactWrap{
  background-color: $lightBackground;
  border-radius: 15px;
  box-shadow: $mainBoxShadow;
  padding: 20px;

  .contactContainer{
    display: flex;
  }

  .contactItem{
    padding: 10px 0px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconWrap{
      border: 1px solid $mainBorder;
      border-radius: 50%;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      margin-right: 15px;
      svg{
        color: $darkLightTextColor;
      }
    }
    &:nth-child(2){
      border-left: 1px solid $grayBorder;
      border-right: 1px solid $grayBorder;
    }
    .contactValue{
      margin-top: 5px;
      color: $mainTitleColor;
      font-weight: 700;
    }
  }
}


@media (max-width: 1020px){
  .contactWrap{
    padding: 0px 20px;
  }
  .contactContainer{
    flex-direction: column;
    .contactItem{
      width: 100%;
      justify-content: start;
      padding: 20px 0px;
      &:nth-child(2){
        border: none;
        border-top: 1px solid $grayBorder;
        border-bottom: 1px solid $grayBorder;
      }
    }
  }
}