@import '../../../styles/colors.module.scss';

.sideBarContainer{
  border-right: 1px solid $grayBorder;
  height: 100%;
  @media (max-width: 768px) {
    display: none;
  }
  .topContainer{
    padding: 20px;
    border-bottom: 1px solid $grayBorder;
    img{
      border-radius: 50%;
      height: 50px;
      width: 50px;
    }
  }
  .sidebarMain{
    padding: 20px 10px;
    a{
      padding: 9px 15px;
      margin-bottom: 10px;
      display: flex;
      cursor: pointer;
      transition: 0.3s all;
      align-items: center;
      font-weight: 600;
      color: $mainTextColor;
      &:hover{
        background-color: #e9fbff;
        color: $mainTitleColor;
      }
      svg{
        margin-right: 15px;
      }
    }
  }
}