@import '../../../styles/colors.module.scss';

.sectionContainer {
  border: 1px solid $mainBorder;
  border-radius: 5px;
  margin-bottom: 20px;

  .sectionTitle {
    background-color: $lightGreenBackground;
    color: $mainTitleColor;
    font-size: 20px;
    padding: 10px 15px;
    font-weight: 600;
  }

  .sectionContent {
    padding: 10px 20px;
    font-weight: 600;

    .sectionItem {
      position: relative;
      left: 0px;
      cursor: pointer;
      margin: 15px 0px;
      transition: .3s;
      display: flex;
      align-items: center;
      &:hover {
        color: $darkLightTextColor;
      }
      
      &.specialItem{
        &:hover {
          left: 5px;
        }
      }
    }
  }
}