@import '../../../../styles/colors.module.scss';

.asideOverlay {
  position: fixed;
  top:0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: #000000b3;
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  &.active{
    opacity: 1;
    visibility: visible;
  }
}

.asideContainer {
  position: fixed;
  top:0px;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: $lightBackground;
  transition: all .3s linear;
  overflow-y: auto;
  z-index: 999;

  &.active{
    left: 0px;
  }

  .asideClose {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $mainTitleColor;
    z-index: 10;
  }

  .asideLogoContainer {
    position: relative !important;
    padding: 30px 20px;
    img{
      height: 50px;
      width: auto;
    }
  }

  .navbarNav {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: list-item;
      text-align: -webkit-match-parent;
    }

    .spaceLi{
      padding: 10px;
      border-bottom: 1px solid $grayBorder;
    }

    .actionLinks{
      border-top: 1px solid $mainBorder;
      border-bottom: 1px solid $mainBorder;
      a{
        background-color: #f6fff8;
        color: $mainTitleColor;
        padding: 12px 15px;
        display: flex;
        font-weight: 600;
        align-items: center;
      }
    }

    .mainMenuItem {
      .menuTitle, a {
        background-color: #f6fff8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 15px;
        color: $mainTitleColor;
        font-weight: 600;
        border-bottom: 1px solid $mainBorder;
        text-decoration: none;

        svg {
          transition: all 0.3s;
        }
      }

      .subMenu {
        display: block;
        list-style: none;
        padding: 0px;
        max-height: 0px;
        overflow: hidden;
        transition: 0.5s linear;

        a {
          padding-left: 30px;
        }
      }

      &.active {
        svg {
          transform: rotate(180deg) !important;
        }

        .subMenu {
          max-height: 500px;
        }
      }
    }
  }
}