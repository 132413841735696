@import '../../../styles/colors.module.scss';

.serviceWraper {
  padding: 75px 0 70px;

  .serviceItem {
    display: block;
    background-color: #fff;
    border: 2px solid $mainBorder;
    transition: .3s;
    color: $mainTextColor;
    position: relative;
    padding: 30px 20px 40px;
    height: 100%;

    .serviceIcon {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 16px rgba(6, 6, 6, 0.06);
      margin: -70px 0 26px;
      background-color: #fff;

      svg {
        color: $mainTitleColor;
      }
    }

    .serviceBody {
      p{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
      }
    }

    a {
      position: relative;
      color: $mainTitleColor;
      font-weight: 600;
      bottom: 10px;

      &:hover {
        color: $darkLightTextColor;
      }
    }

    .readMore {
      position: absolute;
      bottom: 10px;
    }
  }
}