.mapWraper{
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  .mapLoading{
    position: relative;
    width: 100px;
    height: 100px;
  }
  .markIcon{
    width: 30px;
    height: 40px;
    object-fit: contain;
  }
}