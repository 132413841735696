@import '../../../styles/colors.module.scss';

h5{
  color: $mainTitleColor;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 10px;
}

.loginWraper{
  margin-bottom: 20px;
  button{
    padding: 10px 30px !important;
  }
}

.registerWraper{
  .registerDes{
    font-size: 14px;
    svg{
      margin-right: 10px;
      color:$mainLinkHoverTextColor;
    }
  }
}