@import '../../../styles/colors.module.scss';

.ourteamContainer {
  position: relative;
  background-position: center;
  background-size: cover;
  padding: 170px 0px 50px;
  z-index: 1;

  .titleContainer{
    color: $darkLightTextColor;
    text-align: center;
    margin-bottom: 50px;
    .smallTitle{
      font-size: 18px;
      font-weight: 600;
    }
  }
}
.spaceDiv{
  padding: 50px 0px;
}
