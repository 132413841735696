@import '../../../../styles/colors.module.scss';

.menuIconContainer{
  background-color: $lightPinkBackground;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  display: none;
  margin-left: 10px;
}

@media (max-width: 1200px){
  .menuIconContainer{
    display: flex;
  }
}