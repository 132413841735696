@import '../../../styles/colors.module.scss';

.homeSearchContainer{
  margin-top: -85px;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
  .searchWraper{
    border-top: 10px solid $darkLightBackground;
    padding: 30px 30px;
    box-shadow: 0 10px 24px rgba(6, 6, 6, 0.04);
    text-align: left;
    background-color: #fff;
    .btnTab{
      display: flex;
      overflow: auto;
      margin-bottom: 15px;
      button{
        background-color: #bcc8cb;
        height: 45px;
      }
      .active{
        button{
          background-color: $darkLightBackground;
          border-bottom: 4px solid #007ce7;;
        }
      }
    }
    .nomargin{
      margin: 0px;
      .nopadding{
        padding: 0px;
        min-height: 50px;
      }
    }
    label{
      font-weight: $titleFontWeight;
    }
    form{
      button{
        border-top-right-radius: 7px !important;
        border-bottom-right-radius: 7px !important;
        svg{
          flex-shrink: 0;
        }
      }
    }
  }

}
