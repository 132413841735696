@import '../../../../styles/colors.module.scss';

.paymentItem {
  padding: 15px 18px;
  cursor: pointer;
  border-bottom: 1px solid $grayBorder;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $lightGreenBackground;
  }

  label {
    display: flex;
    align-items: center;

    img {
      width: 80px;
      margin-right: 10px;
    }
  }
}

.rightItem {
  padding: 10px 15px;
  border-bottom: 1px solid $grayBorder;

  &:last-child {
    border-bottom: none;
  }

  .priceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
  }

  button {
    font-size: 16px !important;
  }
}

.stripeModalContainer {
  position: relative;
  width: 400px;
  max-width: 100%;
  padding: 15px;

  .cardInputWraper {
    border: 1px solid rgb(207, 207, 207);
    border-radius: 4px;
    padding: 7px 10px;
  }
}