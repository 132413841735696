@import '../../../../styles/colors.module.scss';

.headerTopContainer {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  font-size: 14px;
  justify-content: space-between;
  ul{
    display: flex;
    margin: 0;
    padding: 0;

    .headerContact, .headerSocial{
      display: flex;
      align-items: center;
      opacity: 0.9;
      cursor: pointer;
      margin-left: 15px;
      &:first-child{
        margin-left: 0px;
      }

      &:hover {
        opacity: 1;
      }
    }
    .headerContact svg{
      margin-right: 7px;
    }
  }
}