@import '../../../styles/colors.module.scss';

.pageBGContainer{
  padding: 180px 0 120px;
  background-image: url('../../../../public/images/img/page-bg.png');
  background-size: cover;
  color: $mainTitleColor;
  text-align: center;
  margin-bottom: 60px;
  h1{
    font-weight: 700;
  }
}