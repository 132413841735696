@import '../../../styles/colors.module.scss';

.subscribeContainer{
  margin: 20px 0px -200px;
  .subFormContainer{
    background-color: $darkLightBackground;
    padding:40px;
    border-radius: 10px;
    color: $darkgroundTextColor;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .formHeader{
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      svg{
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
      h4{
        font-size: 28px;
        flex: 1;
        color: $darkgroundTextColor;
        text-align: left;
      }
    }
    p{
      margin-bottom: 15px;
    }
    .formWraper{
      border: 2px solid $mainBorder;
      display: flex;
      border-radius: 5px;
      padding: 5px;
      align-items: center;
      input{
        flex: 1;
        background-color: transparent !important;
        color: $darkgroundTextColor;
        &::placeholder {
          color: $darkgroundTextColor;
          opacity: 1; 
        }
      }
      button{
        font-size: 16px;
        @media (max-width: 768px) {
          padding: 10px 15px !important;
        }
      }
    }
  }

  .iconContainer{
    background-color: $lightBackground;
    border-radius: 10px;
    padding: 30px 40px;
    box-shadow: 0 0 10px 5px rgba(5, 5, 5, 0.05);
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;

    .subsIconWraper{
      border: 1px solid $mainBorder;
      padding: 10px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 576px) {
  .formHeader{
    svg{
      display: none;
    }
  }
}