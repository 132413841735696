@import '../../styles/colors.module.scss';

.pageContainer{
  margin-bottom: 50px;
  display: flex;
  .image404{
    width: 100%;
    height: 100%;
    min-height: 300px;
    object-fit: contain;
  }
  .textWraper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    h1{
      font-weight: 700;
      font-size: 90px;
    }
    .content{
      text-align: center;
      margin: 20px 0px;
      font-weight: 600;
      font-size: 20px;
    }
  }
}
