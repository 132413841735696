@import '../../../styles/colors.module.scss';

.observedContainer{
  padding: 20px;
  h1{
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 10px 0px 30px;
    @media (max-width: 768px) {
      font-size: 21px !important;
    }
  }
}