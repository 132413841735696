@import '../../../styles/colors.module.scss';

.cardContainer{
  padding: 20px;
  box-shadow: 0 1px 7px rgba(0,0,0,.15);
  border-radius: 7px;
  color: #012c6d;
  margin-top: 10px;
  height: 100%;
  .title{
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .des{
    text-align: center;
    height: 75px;
    overflow: hidden;
    color: $mainTextColor;
  }
  .priceWrapper{
    text-align: center;
    .currency{
      font-size: 22px;
      margin-right: 10px;
    }
    .price{
      font-size: 35px;
      font-weight: 600;
    }
  }
  .per_month{
    color: $mainTextColor;
    text-align: center;
    margin-bottom: 10px;
  }
  .cardMain{
    .optionItem{
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg{
        margin-right: 7px;
        color: #00d37b;
        width: 20px;
        height: 20px;
      }
    }
  }
}