@import '../../../styles/colors.module.scss';

.detailItemWraper{
  box-shadow: $mainBoxShadow;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  .iconWraper{
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: $darkLightTextColor;
    color: $darkgroundTextColor;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
  }
  .detailContentWraper{
    .detailTitle{
      color: $mainTitleColor;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
}
