@import '../../../styles/colors.module.scss';

.Wraper {
  padding: 180px 0 70px;
  background-color: $lightGreenBackground;
  margin-bottom: 200px;
  .title {
    text-align: center;
    font-weight: 700;

    .smallTitle {
      font-size: 18px;
      color: $darkLightTextColor;
    }

    h3 {
      color: $mainTitleColor;
      margin-bottom: 50px;
    }
  }

  .sliderWraper {
    margin-bottom: 100px;
    .slideContainer {
      border-radius: 10px;
      border: 1px solid $lightGreenBorder;
      height: 100%;

      &:hover{
        background-color: #fff;
      }

      a {
        color: $mainTitleColor;
        font-weight: 600;

        &:hover {
          color: $darkLightTextColor;
        }
      }

      .imageWraper {
        height: 200px;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .slideContent {
        padding: 20px 20px 45px;

        .contentTitle {
          margin-bottom: 10px;

          a {
            font-size: $titleFontSize;
          }
        }

        .contenMain {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }

    .readMore {
      padding: 0px 20px;
      position: absolute;
      bottom: 18px;
    }
  }
}


@media (max-width: 576px) {
  .imageWraper {
    height: 250px !important;
  }
}