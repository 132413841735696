@import '../../../../styles/colors.module.scss';

.menusWrap{
  margin: 50px 0px;
  .logoSocial{
    padding-right: 40px;
    display: flex;
    height: calc(100% - 10px);
    flex-direction: column;
    justify-content: space-between;
    .footerLogo{
      height: 50px;
    }
    .footerShortDes{
      margin: 30px 0px;
      font-weight: 500;
      line-height: 1.8;
    }
    .social{
      .socialBtn{
        display: inline-flex;
        width: 43px;
        height: 43px;
        border-radius: 50%;
        border: 1px solid $grayBorder;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        &:hover{
          background-color: $darkBackground;
          svg{
            color: $lightBackground;
          }
        }
        svg{
          color: $mainTitleColor;
          font-size: 18px;
        }
      }
    }
  }

  .menuTitle{
    color: $mainTitleColor;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 13px;
  }
  .menuContent{
    a{
      display: block;
      margin-bottom: 13px;
      color: $mainTextColor;
      &:hover{
        color: $darkLightTextColor;
      }
      &:last-child{
        margin-bottom: 0px !important;
      }
    }
  }
}