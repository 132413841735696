@import '../../../styles/colors.module.scss';

.WorkProcessContainer {
  padding: 20px 0px 50px;
  .titleWraper{
    .smallTitle{
      font-weight: 700;
      color: $darkLightTextColor;
    }
    h3{
      line-height: 1;
      font-size: 46px;
      text-align: left;
    }
  }
  .stepContainer{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 35px;
      width: calc(100% - 30px);
      height: 1px;
      z-index: -1;
      background-color: #ebf2f3;
    }
  }

  .contentWraper{
      display: flex;
      height: 100%;
  }

  .iconWraper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 110px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px 5px rgba(8, 8, 8, 0.03);
    margin-right: 28px;
    margin-bottom: 20px;
    svg{
      width: 57px;
      height: 100%;
      color: $darkLightBackground !important;
    }
  }
  .stepContent{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    h5{
      color: $mainTitleColor;
      font-weight: 600;
    }
    .contentMain{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;      
    }
    .stepMarkContainer{
      .step{
        color:$darkLightTextColor;
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0px 10px;
      }
      .stepCircle{
        position: relative;
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $darkLightTextColor;
        margin-left: 6px;    
        &::before{
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          background-color: #00acb1;
          opacity: .2;
          width: calc(100% + 16px);
          height: calc(100% + 16px);
          border-radius: 50%;          
        }    
      }
    }
  }
}



@media (max-width: 1100px) {
  .contentWraper{
    flex-direction: column;
  }
  .stepContainer{
    &::after{
      display: none;
    }
  }
}