@import '../../../styles/colors.module';

.noContent{
  margin-bottom: 300px;
  @media (max-width: 760px){
    margin-bottom: 200px;
  }
}

.sliderContainer {
  z-index: 1;
  &:hover .navBtn{
    opacity: 1;
  }
  .navBtn {
    position: absolute;
    top: 50%;
    z-index: 10;
    opacity: 0;
    transition: all 0.3s;
    button{
      padding: 10px 12px !important;
    }
  }

  .navPrevBtn {
    left: 20px;
  }

  .navNextBtn {
    right: 20px;
  }
}

.slidWraper {
  position: relative;
  height: 780px;
  display: flex;
  align-items: center;
  justify-content: center;

  .slidImg {
    position: absolute;
    width: 100%;
    height: 100%;

    &::before {
      background-color: rgba(0, 92, 103, 0.5);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .sliderContent {
    z-index: 10;
    color: $darkgroundTextColor;
    text-align: center;
    font-weight: 500;
    width: 60%;
    h5{
      color: $darkgroundTextColor;
    }
    h1 {
      margin-bottom: 40px;
      line-height: 1;
      color: $darkgroundTextColor;
      font-weight: 600;
    }
    .btnContainer{
      display: flex;
      justify-content: center;
      align-items: center;
      button{
        margin: 0px 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .slidWraper {
    height: 700px;
  }
  .sliderContent{
    width: 80% !important;
  }
}

@media (max-width: 576px) {
  .slidWraper {
    height: 550px;
  }
  .btnContainer{
    button{
      padding: 10px 12px !important;
      font-size: 14px !important;
    }
  }
}