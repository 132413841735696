.loadingContainer{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3e463e99;
  img{
    width: 70px;
    height: 70px;
  }
}