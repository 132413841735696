@import '../../../styles/colors.module.scss';

.commonBtn{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  border: 0;
  font-weight: 500;
  transition: .3s;
  overflow: hidden;

  &.disabled{
    cursor: default !important;
    background-color: $disabledBackground !important;
    color:$disabledTextColor !important;
  }

  &::before{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 0;
    border-radius: 0;
    background-color: #2c2c2c25;
    transition: .3s;
  }
  &:hover:not(.disabled){
    &::before{
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.smallMarkBtn{
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 13px;
  border-radius: 17px;
  transition: .3s;
  overflow: hidden;

  &.disabled{
    cursor: default !important;
    background-color: $disabledBackground !important;
    color:$disabledTextColor !important;
  }

  &.success:not(.disabled){
    background-color: #00acb1;
    color: $lightBackground;
    &:hover{
      background-color: #007377;
    }
  }
  
  &.primary:not(.disabled){
    background-color: #5096e5;
    color: $lightBackground;
    &:hover{
      background-color: #2961a2;
    }
  }

  &.danger:not(.disabled){
    background-color: #e15757;
    color: $lightBackground;
    &:hover{
      background-color: #9e3838;
    }
  }
  &.white:not(.disabled){
    background-color: white;
    color: #005963;
    &:hover{
      background-color: #b8b8b8;
    }
  }
}

.outLineBtn{
  background-color: $lightBackground;
  transition: .3s;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.disabled{
    cursor: default !important;
    border: 2px solid $disabledTextColor !important;
    color: $disabledTextColor !important;
    &:hover{
      cursor: default !important;
      border: 2px solid $disabledTextColor !important;
      color: $disabledTextColor !important;
    }
  }  

  &.success:not(.disabled){
    border: 2px solid #00acb1;
    color: #00acb1;
    &:hover{
      background-color: #00acb1;
      color: $darkgroundTextColor;
    }
  }
  
  &.primary:not(.disabled){
    border: 2px solid #5096e5;
    color: #5096e5;
    &:hover{
      background-color: #5096e5;
      color: $darkgroundTextColor;
    }
  }

  &.danger:not(.disabled){
    border: 2px solid #e15757;
    color: #e15757;
    &:hover{
      background-color: #e15757;
      color: $darkgroundTextColor;
    }
  }

}