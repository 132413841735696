@import '../../../../styles/colors.module.scss';

.searchContainer {
  margin: 0px 15px;
  cursor: pointer;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, .8);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  opacity: 0;
  visibility: hidden;

  &.open{
    opacity: 1;
    visibility: visible;
    .searchForm{
      width: 70%;
    }
  }

  .modalClose {
    position: absolute;
    top: 60px;
    right: 60px;
    color: $darkgroundTextColor;
    cursor: pointer;
  }

  .searchForm{
    position: relative;
    transition-duration: .3s;
    transition-delay: .3s;
    width: 0px;
    input{
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #fff;
      font-size: 40px;
      height: 80px;
      width: 100%;
      outline: none;
      color: #fff;
      font-weight: 400;
    }
    .searchBtn{
      position: absolute;
      top: 50%;
      right: 20px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background-color: transparent;
      border: 0;
      color: #fff;
      font-size: 30px;
      outline: none;
      transition: .3s;
      cursor: pointer;
      padding: 0;      
    }
  }
}

@media (max-width: 576px) {
  .modalContainer.open .searchForm{
    width: 90%;
  }
}