@import '../../../styles/colors.module.scss';

.blogsContainer {
  padding-top: 80px;
  padding-bottom: 100px;
  background-color: $lightGreenBackground;
  .titleContainer{
    color: $darkLightTextColor;
    text-align: center;
    margin-bottom: 50px;
    .smallTitle{
      font-size: 18px;
      font-weight: 600;
    }
    h3{
      color: $mainTitleColor;
      font-weight: 700;
    }
  }
}

.slideContainer{
  border-radius: 7px;
  background-color: $lightBackground;
  color: $mainTextColor;
  height: 100%;
  padding: 20px;
  border: 1px solid $lightGreenBorder;

  .imageWraper{
    position: relative;
    width: 100%;
    height: 220px;
    overflow: hidden;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: top;
      transition: all 0.3s;
      border-radius: 7px;
      &:hover{
        transform: scale(1.1) !important;
      }
    }
  }

  .blogDate{
    position: absolute;
    bottom: -15px; 
    right: 10px;
  }
  
  .slideContent{
    padding-top: 30px;
    .userCat{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 15px;
      a{
        color: $mainTextColor;
        &:first-child{
          margin-right: 10px;
        }
        &:hover{
          color: $darkLightTextColor !important;
        }
        svg{
          margin-right: 2px;
        }
      }
    }
    .blogTitle{
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      a{
        font-size: 18px;
        font-weight: 700;
        color: $mainTitleColor;
        &:hover{
          color: $darkLightTextColor !important;
        }
      }
    }
  }

}