@import '../../../styles/colors.module.scss';

.headerWraper{
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;

  .headerTopWraper{
    padding: 0px 65px;
    background-color: $darkBackground;
    color: $darkgroundTextColor;
  }

  .headerNavWraper{
    padding: 0px 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $darkgroundTextColor;
    background-color: $headerNavBackground;
  }

  .container{
    .headerTopWraper{
      padding: 0px 0px;
      background-color: transparent;
      color: $mainTitleColor;
    }
    
    .headerNavWraper{
      padding: 0px 10px;
      color: $mainTitleColor;
      background-color: $lightBackground;
    }
  }
}

@media (max-width: 760px){
  .headerTopWraper{
    display: none;
  }
}


@media (max-width: 548px){
  .headerTopWraper{
    display: none;
  }
  .headerNavWraper{
    padding: 0px 15px !important;
  }
}

