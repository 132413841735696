@import '../../../../styles/colors.module.scss';

.navbarNav {
  flex-direction: row;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.mainMenuItem {
  position: relative;
  transition: .3s;

  &:hover .subMenu {
    opacity: 1;
    visibility: visible;
    top: 95%;
  }

  .menuItem a {
    &:hover {
      color: $mainLinkHoverTextColor !important;
    }
  }

  &:first-child>a {
    padding-left: 0;
  }

  &:last-child>a {
    padding-right: 0;
  }

  a {
    display: block;
    padding: 20px;
    color: inherit;
    font-weight: 500;
    font-family: "Heebo", sans-serif;
    position: relative;
    font-size: 17px;
    font-weight: 500;
    transition: 0.3s;
    text-decoration: none;

    &:hover {
      color: $mainLinkHoverTextLightColor;
    }
  }

  .subMenu {
    position: absolute;
    top: 110%;
    padding: 0;
    min-width: 250px;
    background-color: $darkgroundTextColor;
    border-radius: 10px;
    box-shadow: 0 0 40px 5px rgba(4, 4, 4, 0.03);
    transition: .3s;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    list-style: none;
    
    .menuItem {
      position: relative;
      transition: .3s;
      border-bottom: 1px solid $mainBorder;
      &:last-child {
        border-bottom: none !important;
      }
      a {
        padding: 15px;
        display: flex;
        font-weight: 400;
        align-items: center;
        color: $mainTextColor;
        position: relative;
      }
    }
  }
}

.multiLangMenu {
  right: 0px !important;
  min-width: 180px !important;
}

@media (max-width: 1200px) {
  .navMenu {
    display: none;
  }
}