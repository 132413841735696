@import '../../../styles/colors.module.scss';

.accountContainer{
  padding: 20px;
  h1{
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin: 10px 0px 30px;
    @media (max-width: 768px) {
      font-size: 21px !important;
    }
  }
  .formWrapper{
    border: 1px solid $grayBorder;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    margin-bottom: 15px;
    label{
      font-size: 15px;
    }
    .formTitle{
      color: $mainTitleColor;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  button{
    padding: 8px 20px !important;
  }
}

.modalContainer{
  width: 500px;
  max-width: 100%;
  .modalHeader{
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 600;
    border-bottom: 1px solid $grayBorder;
  }
  .modalMain{
    padding: 15px;
    color: red;
    font-size: 20px;
  }
  .modalFooter{
    padding: 10px 15px;
    border-top: 1px solid $grayBorder;
  }
}