.blue-info{
  display: flex;
  margin: 10px 0px;
  border: 1px solid #bfe3ef;
  border-radius: 4px;
  align-items: center;
  background: linear-gradient(to bottom,#cce7f1 0%,#e4f4fa 100%);
  font-size: 14px;
  color: black;
  padding: 5px 10px;
  svg{
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}