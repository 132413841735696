@import '../../../styles/colors.module.scss';

.PagenationContainer{
  display: inline-flex;
  align-items: center;
  .PageBtn{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      background-color: white;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      margin: 3px;
      box-sizing: border-box;
      font-weight: 600;
      cursor: pointer;
      background-color: $lightGreenBackground;
      &:hover{
          background-color: $lightGreenBorder;
      }
      &.active{
          background-color: $darkLightBackground;
          color: white;
      }
      &.disabled{
          color:rgb(189, 189, 189);
          background-color: rgb(249, 249, 251);
          cursor: inherit;
      }
  }
  .PageDot{
      display: inline-flex;
      font-weight: 600;
  }
}