// text Colors -------------------
$mainTitleColor: #005963;
$mainTextColor: #686a6f;
$darkgroundTextColor: white;
$disabledTextColor: #b9b9b9;
$darkLightTextColor: #00acb1;
$mainLinkHoverTextColor: #008ac0;
$mainLinkHoverTextLightColor: #0aeffb;

// ---------- Font Size ---------------
$smallFontSize: 14px;
$mainFontSize: 16px;
$titleFontSize: 20px;
$titleFontWeight: 500;

// background Colors -------------------
$darkBackground: #005963;
$darkLightBackground: #00a6b9;
$lightBackground: #fff;
$disabledBackground: #e9e9e9;
$headerNavBackground: rgba(0,89,99,.5);
$lightGreenBackground: #effbfa;
$lightPinkBackground: #fcf7ff;

// border Colors ----------------------
$mainBorder: #ebf2f3;
$grayBorder: #dde2e3;
$lightGreenBorder: #bbf5fd;


// -------- box shadow ---------
$mainBoxShadow: 0 10px 30px rgba(205, 205, 205, 0.05);

