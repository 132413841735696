@import '../../../styles/colors.module.scss';

.clientSaysContainer {
  padding-top: 80px;
  padding-bottom: 40px;

  .titleContainer {
    color: $darkLightTextColor;
    text-align: center;
    margin-bottom: 50px;

    .smallTitle {
      font-size: 18px;
      font-weight: 600;
    }

    h3 {
      color: $mainTitleColor;
      font-weight: 700;
    }
  }
}

.slideContainer {
  border-radius: 7px;
  background-color: $lightGreenBackground;
  color: $mainTextColor;
  height: 100%;
  padding: 20px;
  text-align: center;

  .imageWraper {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .contentWraper {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 100px);

    .content {
      color: $darkLightTextColor;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
    }

    .name {
      padding: 10px 0px 5px;
      font-size: 20px;
      font-weight: 700;
      color: $mainTitleColor;
    }
  }
}