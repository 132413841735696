@import '../../../../styles/colors.module.scss';

  .multiItem {
    padding: 5px 15px;
    display: flex;
    position: relative;
    align-items: center;
    font-weight: 400;
    align-items: center;
    color: $mainTextColor;
    position: relative;
    cursor: pointer;

    img {
      margin-right: 10px;
    }

    &:hover {
      color: #00acb1;
    }
  }