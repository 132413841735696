.openHourTable tbody tr td{
  padding: 4px 10px 0px 10px;
  select{
    margin-bottom: 4px;
    padding: 3px 10px;
    font-size: 12px;
  }
  svg{
    margin: 0px 5px;
    cursor: pointer;
  }
  .selectContainer{
    height: 41px;
    font-size: 12px !important;
  }
} 