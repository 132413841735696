.modalWraper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.501);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal{
    max-width: 95%;
    background-color: white;
    border-radius: 5px;
  }

}