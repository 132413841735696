@import 'swiper/css';
@import "swiper/css/navigation";

@import './styles/colors.module.scss';
@import './styles/customInputElement.scss';
@import './styles/commonElements.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: $mainTextColor;
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  color: $mainTitleColor;
  font-weight: 700;
  text-align: center;
}

h1 {
  font-size: 72px;
}

h2 {
  font-size: 62px;
}

h3 {
  font-size: 52px;
}

h4 {
  font-size: 35px;
}

a {
  text-decoration: none !important;
  color: $mainTitleColor;
  &:hover{
    color: $darkLightTextColor;
  }
}

.container {
  margin: 0px auto;
  width: 100%;
  max-width: 540px !important;
}
.userMain{
  margin-top: 140px;
  @media (max-width: 760px){
    margin-top: 100px;
  }
}
.flex-1{
  flex:1;
}
.row.noMargin {
  margin: 0px !important;

  &>.col, &>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 42px !important;
  }

  h2 {
    font-size: 38px !important;
  }

  h3 {
    font-size: 33px !important;
  }

  h4 {
    font-size: 28px !important;
  }
}

.font-14{
  font-size: 14px;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
}