@import '../../../../styles/colors.module.scss';

.copyRightWrap{
  padding: 30px 0px;
  border-top: 1px solid $grayBorder;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .copyRightYear{
    color: $mainTitleColor;
  }
  .CopyRightMenus{
    display: flex;
    flex-wrap: wrap;
    a{
      margin-bottom: 1rem !important;
      padding: 0px 20px;
      border-right: 2px solid $disabledBackground;
      color: $mainTextColor;
      line-height: 1;
      &:last-child{
        border-right: none;
        padding-right: 0px;
      }
      &:first-child{
        padding-left: 0px;
      }
      &:hover{
        color: $darkLightTextColor;
      }
    }
  }
}


@media (max-width: 760px){
  .copyRightWrap{
    flex-direction: column;
    align-items: flex-start;
  }
}
