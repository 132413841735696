@import '../../../styles/colors.module.scss';

.homeContainer {
  .greetingContainer {
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    margin: 15px 0px;
  }

  .MydatesContainer {
    padding: 20px;
    border-bottom: 1px solid $grayBorder;
    h2 {
      font-weight: 600;
      font-size: 23px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 20px !important;
      }
    }

    .itemRow{
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    img {
      width: 170px;
      margin: 0px 40px 0px 20px;
    }
  }

  .aboutContainer{
    padding: 20px;
  }

}